.bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s ease-in-out;
}
.hamburger, .x{
    margin-right:5%;
}
.x:hover, .hamburger:hover{
    cursor: pointer;
}
.x .bar:nth-child(2){
   opacity: 0;
}

.x .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
    margin-right:5%;
}

.x .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
    margin-right:5%;
}