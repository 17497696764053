.bottom{
    background:var(--secondary);
    height:10vh;
    margin-top: 5vh;
    width:100%;
    /*Margin collapse so need display:flex*/
    display: flex;
    justify-content:center;
    align-items: center;
    color: var(--darker-grey);
}
.icon{
    height: 2rem;
    width: 2rem;
    margin: 0 5px;
}
.copyright{
    height: 0.5rem;
    width: 0.5rem;
}