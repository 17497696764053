table{
    border-collapse: collapse;
    width: 100%;
}

/*Have some schedule settings in bigger screen view*/
.row {
    height: 10vh;

}

.row:nth-child(odd) {
    background-color: var(--secondary);

}
td{
    white-space: nowrap;
    padding-right: 40px;
}

/*Rounds corner of logo area for table row*/
td:nth-child(1) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-left:10px;
}

/*Rounds corner of date (TBD) area for table row*/
td:nth-child(4) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
/*for drop down menu*/
.dropdown{
    border-radius: 2px;
    padding:5px;
    font-size:1.05rem;
    width:auto;
    margin: 0 15% 10px 15%;
    border:none;
    background-color: var(--light-grey);
    color:var(--darker-grey);
}
.dropdown:hover{
    background-color: var(--grey);
}
