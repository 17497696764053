.side-menu{
    position:absolute;
    width:100%;
    left:0;
    /* top: -50vh; */
    top: 12vh;
    background-color:var(--secondary);
    display:flex;
    flex-direction:column;
    align-items:start;
    /* opacity:0; */
    height:0;
    transition: height 0.5s ease-in-out;
    overflow:hidden;
    /* z-index:0; */
}
.side-menu.active{
    height:100vh;
    /* top:12vh; */
    /* opacity:1; */
}
.side-link{
    margin: 6vh 5% 0 5%;
    text-decoration: none;
    color:var(--primary);
}
.side-button{
    background-color:var(--highlight);
    color:var(--primary);
    border-radius:6px;
    border:none;
    font-weight: bold;
    font-size: 0.875rem;
    height:3em;
    width:auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}
.side-button:hover{
    background-color: var(--hover-highlight);
}
.side-link:hover{
    color:var(--highlight);
}

