/* .schedule{
    background-image:url('mitch.png');
} */
/*Table for schedule*/
.center{
    text-align:center;
}
.background{
    z-index:-1;
    position:absolute;
    opacity: 0.2;
    object-fit: cover;
    object-position: center;
    height: 100vh;
    width:100vw;
    overflow:hidden;
    /* transition: all 1s ease-in-out; */
}
.background.active{
    position:fixed;
    top:12vh;
    left:0;
}
.schedule-roster {
    overflow-x: scroll;
    width: 70%;
    margin: 5vh 15%;
          
}

.vs {
    background-color: var(--primary);
    color: white;
    padding: 0 4px;
    font-size: 0.7em;
}
.at {
    font-size: 0.7em;
    background-color: var(--darker-grey);
    color:white;
    padding: 0 4px;
}
.win{
    color: var(--green);
}
.loss{
    color: red;
}
.schedule-logo {
    max-width: 8vh;
    max-height: 8vh;
    object-fit: fill;

}
.nationals-logo{
    max-width: 4vh;
    max-height: 4vh;
}