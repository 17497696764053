:root {
  --primary: #002877;
  --secondary: #F9FAFB;
  --light-grey: #D7D2CB;
  --grey: #C0BBB3;
  --darker-grey: #5A606A;
  --highlight: #f8bd0d ;
  --hover-highlight: #e6be00;
  --green:  #2E8B57;
    /* define the amount of space to be given from within the element or from outside the element whenever a scroll event occurs. Does not apply when regularly scrolling because there is no snap position, only href will have a snap position*/
  scroll-padding-top:14vh;
}
@media (min-width: 1500px){
  *{
    font-size:1.2rem;
  }
}
*{
  box-sizing:border-box;
  color:var(--darker-grey);
}
body {
  margin: 0;
  /* font family is inherited which is why it applies to all children */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.section-header{
  margin: 0 5% 5vh 5%;
  font-style:bold;
  color: var(--primary);
  font-size:2em;
  text-align:center;
  text-transform:uppercase;
  letter-spacing: .1rem;
}
.image{
  width:100%;
}