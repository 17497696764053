.colby-logo{
    width:7vh;
    margin-left:5%;
}
.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height:12vh;
    top:0;
    left:0;
    position:sticky;
    background-color: var(--primary);
    /* z-index:100; */
}
.options{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    
}
/* .nav-bar:hover{
    background: var(--primary);
} */

/* Styles underlining for menu*/
.nav-link {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color:white;
}
.nav-link:hover{
    color: var(--highlight);
}
/* .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: white;

    transition: transform 0.25s ease-out;
}

.nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: center;
} */

.nav-bar-button{
    background-color:var(--highlight);
    color:black;
    border-radius:6px;
    font-weight: bold;
    height:3em;
    width:auto;
    border: none;
    font-size: 0.875rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.nav-bar-button:hover{
    /* background-color: var(--darker-grey); */
    /* color: var(--highlight);
    border-color: var(--highlight); */
    background-color: var(--hover-highlight);
}