/*Contact us section*/
@media(max-width: 1200px){
    .contact-us{
        margin: 0 15% !important;
    }
} 
.contact-us {
    margin: 0 30%;
    align-content: center;
    background-color: white;
    color: black;
}

.contact-us-description {
    text-align: center;

}

.contact-us label {
    display: inline-block;
    margin-top: 10px;
}

input,
textarea {
    margin: 10px 0 0 0;
    width: 100%;
    background-color: #F9FAFB;
    padding: 16px;
    font-size: 16px;
    border: none;
    color: black;
    border-radius: 6px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;


}

input:focus,
textarea:focus {
    outline: 2px solid #015ECC;

}

button[type="submit"] {
    background-color: var(--highlight);
    color: black;
    height: 3em;
    width:auto;
    font-weight: bold;
    font-size: 0.875rem;
    border-radius: 6px;
    margin-top: 10px;
    border:none;
}

button[type="submit"]:hover {
    background-color: var(--hover-highlight);
}

::placeholder {
    color: #9BA3AF;

}